import createAuthContext from './lib/createAuthContext'

const clientId = "WebOAuthClient"
const clientSecret = ""
const provider = "https://openam-aiahk-ae2-dev.id.forgerock.io/am/oauth2/realms/root/realms/alpha/"
const tokenEndpoint = "https://openam-aiahk-ae2-dev.id.forgerock.io/am/oauth2/realms/root/realms/alpha/oauth2/access_token"

export const {AuthContext, Authenticated, useToken} = createAuthContext({
  clientId,
  clientSecret,
  provider
})
